/*
    Site Wide Customizations
*/
body {
    font-family: Montserrat,ui-sans-serif;
}

.form-check-input:checked {
    background-color: $yellow;
    border-color: $yellow;
}

.form-check-input:focus {
    box-shadow: none;
    border-color: $yellow;
}

.text-primary {
    color: $brown !important;
}

.actions {
    background-color: rgba(0,0,0,0.2);
}

#action-icons {
    ul {
        padding: 0;

        li {
            list-style-type: none;
        }

        li.separator {
            margin-top: 1rem;
        }
    }

}

a, .search-result .title a, #sort-header .dropdown-selected #action-icons a:not(.btn) {
    color: $blue;

    &:hover, &:focus, &:visited {
        color: $blue;
        text-decoration: underline;
    }
}

/*
    Header Customizations
*/
#top-bar {
    background-color: $white !important;

    .col-md-3 {
        max-width: 100%;
        width: 100%;
        height: auto;

        #logo {
            width: 215px;
            height: auto;
        }
    }

    #nav-header {
        justify-content: end;

        ul.navbar-nav li.nav-item a.nav-link {
            color: $primary;

            &:hover, &:focus {
                color: $white;
                background-color: $primary;
            }
        }
    }

    #browse-menu, #search-box button, #user-menu {
        background-color: $yellow;
        border: 0px;
        border-radius: 30px;
        padding: 10px;
        height: 40px;
        float: right;
        min-width: 10%;
        color: $text-gray;
        font-size: 16px;
        font-weight: bold;

        &:hover, &:focus {
            background-color: $yellow;
            box-shadow: none;
        }
    }

    .navbar-toggler {
        background-color: $yellow;
        border-color: $yellow;
        float: right;

        &:hover, &:focus {
            background-color: $yellow;
            border-color: $yellow;
            box-shadow: none;
        }
    }

    #search-box-input {
        border-radius: 5px;
        margin: 0 5px;

        &:focus {
            box-shadow: none;
            border-color: $yellow;
        }
    }
}

#site-slogan {
    background-color: $primary;
}

.atom-btn-outline-success {
    background-color: $primary;
    &:hover,
    &:focus {
      background-color: $yellow;
      border-color: $yellow;
    }
}

.yui-ac > ul {
  margin: 0 0 $spacer * 0.5 0;

  li {
    cursor: pointer;
    font-size: $font-size-base;

    &:hover {
      list-style-image: url(../../../images/delete.png);
    }

    input {
      width: 100%;
      margin: $spacer * 0.25 0;
      padding: $input-padding-y-sm $input-padding-x-sm;
      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;
      font-size: $font-size-base;

      &:focus {
        outline: none;
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
      }
    }
  }
}

@media print {
    .btn,.accordion-button,.atom-section-header a,#update-check,header,#site-slogan,#sidebar,#context-menu,section.actions,footer,#sfWebDebug,button.clipboard,a.clipboard-print,.browse-options,#sort-header {
        display: none !important
    }
    article {
        display: flex;
        flex-wrap: wrap;
    }

    article .col-lg-3,
    article .col-lg-9 {
        flex: 1;
        display: flex;
    }

    article .img-thumbnail {
        width: 100%;
        height: auto;
    }

}
